<template>
  <b-modal
      id="add-new-payment"
      centered
      hide-footer
      size="md"
      title="Add New Payment Method"
      @shown="onModalShown"
  >
    <validation-observer ref="setupPaymentsValidation">
      <form @submit.prevent="addPaymentMethod">
        <div>

          <validated-form-input
              label="Cardholder Name"
              input-id="cardholder-name-input"
              validation-name="cardholder name"
              placeholder="Enter the name of the cardholder"
              v-model="cardholderName"
          />


          <b-form-group label="Payment Information" label-for="payment-information-input">
            <div class="stripe-input-wrapper">
              <div v-if="!stripeInstance" class="stripe-loading-text">Stripe loading...</div>
              <div id="stripe-card-element"></div>
            </div>
          </b-form-group>

          <b-button
              type="submit"
              variant="primary"
              class="w-100 submit-form-btn mt-2"
              :disabled="loading || !selectedLocations.length"
          >
            <template v-if="loading">
              <b-spinner small class="mr-50"/>
              <span class="align-middle">Loading</span>
            </template>
            <span v-else>Add New</span>
          </b-button>
        </div>
      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {BModal, BButton, BSpinner, BFormGroup} from 'bootstrap-vue';
import toastsMixin from "@/mixins/toastsMixin";
import SelectLocationsBox from "@/layouts/components/auth/SelectLocationsBox";
import LocationsService from "@/services/LocationsService";
import {ValidationObserver} from "vee-validate";
import ValidatedFormInput from "@/layouts/components/shared/ValidatedFormInput";
import {nextTick} from "@vue/composition-api";
import PaymentsService from "@/services/PaymentsService";

export default {
  name: 'LocationsModal',
  components: {
    BModal,
    BButton,
    BSpinner,
    SelectLocationsBox,
    ValidatedFormInput,
    ValidationObserver,
    BFormGroup
  },
  mixins: [toastsMixin],
  data() {
    return {
      loading: false,
      locationsLoading: true,
      locations: [],
      displayedLocations: [],
      locationsSearch: '',
      cardholderName: '',
      clientSecret: null,
      stripeInstance: null
    }
  },
  computed: {
    selectedLocations() {
      return this.locations.filter(location => location.selected);
    }
  },
  watch: {
    locationsSearch() {
      this.displayedLocations = this.locations.filter(location => {
        return location.name.toLowerCase().includes(this.locationsSearch.toLowerCase());
      });
    }
  },
  methods: {
    onModalShown() {
      this.fetchLocations();
    },
    async fetchLocations() {
      this.locationsLoading = true;
      const {data: {locations}} = await LocationsService.get.locations()
      this.locations = locations
          .map(location => ({...location, selected: !!location.active}))
          .sort(this.sortLocationCompareFunc);
      this.displayedLocations = this.locations;
      this.locationsLoading = false;
    },
    sortLocationCompareFunc: (a, b) => {
      if (a.selected > b.selected) return -1;
      if (a.selected < b.selected) return 1;
      return 0;
    },
    selectLocation(location) {
      location.selected = !location.selected;
      this.locations.sort(this.sortLocationCompareFunc);
      this.displayedLocations.sort(this.sortLocationCompareFunc);
    },
    async initStripe() {
      const {data: {intent: {client_secret}}} = await PaymentsService.get.paymentsSecret();
      this.clientSecret = client_secret;
      this.stripeInstance = Stripe('pk_live_51LDVREBRim70T8FMSdPRHOfnY0FFLNo6v9OS2AZLgOqySteygdnrniNG0z7OM5YqBRxTuiU5ydwJcBVjRyXCamwr00Oi6T33vQ');
      this.stripeCardElement = this.stripeInstance.elements().create('card');
      this.stripeCardElement.mount('#stripe-card-element');
    },
    async addPaymentMethod() {
      const isFormValid = await this.$refs.setupPaymentsValidation.validate();

      if (!isFormValid || this.loading) return;

      this.loading = true;
      const {setupIntent, error} = await this.stripeInstance.confirmCardSetup(
          this.clientSecret,
          {
            payment_method: {
              card: this.stripeCardElement,
              billing_details: {name: this.cardholderName}
            }
          }
      );

      if (error) {
        this.showErrorToast(error.message);
        this.loading = false;
        return
      }

      await PaymentsService.post.addPaymentMethod(setupIntent.payment_method, this.billing);

      this.loading = false;
      this.showSuccessToast('Payment successful');

      //Payment method added
      this.$emit('payment-method-added')

      // Close the modal
      this.$bvModal.hide('add-new-payment');


    }
  },
  updated() {
    if (this.stripeInstance === null && document.querySelector("#stripe-card-element")) {
      this.initStripe();
    }
  }
}
</script>

<style lang="scss">
#locations-modal {
  .btn.submit-form-btn {
    padding: 16px 28px;
    border-radius: 8px;
  }
}
</style>
